var page_home_loaded = 1;
global.page_home_loaded = page_home_loaded;

var flexsliderLoad = require('swiper');
	// smInit = require('scrollmagic'),
	// animation = require('../../../node_modules/scrollmagic/scrollmagic/minified/plugins/animation.gsap.min.js'),
	// tmInit = require('../../../node_modules/gsap/src/minified/TimelineMax.min.js');

page_home = {
	load: function(){	
		this.swiperInit();
		this.linkCheck();
		this.labelChange();
		this.smoothScroll();
		this.visitaScoll();
		this.newsScroll();
	},
	linkCheck: function(){
		var currentLink = $('.swiper-slide-active').data('link'),
    		homeUrl = window.location.href;

    	if (currentLink == homeUrl) {
    		$('.continue_link').attr('href', '#visita_content').addClass('scroll');
    		// $('body').css({
    		// 	'overflow' : 'visible',
    		// 	'height' : 'auto'
    		// });
    	} else {
    		$('.continue_link').attr('href', currentLink).removeClass('scroll');
    		// $('body').css({
    		// 	'overflow' : 'hidden',
    		// 	'height' : '100vh'
    		// });
    	}
	},
	labelChange: function(){
		var prevLabel = $('.swiper-button-prev span'),
			nextLabel = $('.swiper-button-next span'),
			prevSlideLabel = $('.swiper-slide-prev').data('name'),
			nextSlideLabel = $('.swiper-slide-next').data('name');

		prevLabel.empty().text(prevSlideLabel);
		nextLabel.empty().text(nextSlideLabel);
	},
	swiperInit: function () {
		var homeSlider = new Swiper ('.swiper-container', {
			effect: 'coverflow',
			paginationClickable: true,
			nextButton: '.swiper-button-next',
    		prevButton: '.swiper-button-prev',
	        grabCursor: true,
	        centeredSlides: true,
	        slidesPerView: 'auto',
	        // initialSlide: 1,
			loop: true,
			longSwipesMs: 650,
			resistanceRatio: .5,
			keyboardControl: true,
			autoplay: 5000,
			speed: 1200,
			autoplayDisableOnInteraction: true,
	        coverflow: {
	            rotate: 30,
	            stretch: 0,
	            depth: 100,
	            modifier: 1,
	            slideShadows : true
	        },
	    });
		homeSlider.update();
	    homeSlider.on('SlidePrevStart', function(){
	    	page_home.linkCheck();
	    	page_home.labelChange();
	    })
	    homeSlider.on('SlideNextStart', function(){
	    	page_home.linkCheck();
	    	page_home.labelChange();
	    })
	},
	smoothScroll: function(){
		$('.scroll').on('click', function(){
			event.preventDefault();
		    $('html, body').animate({
		        scrollTop: $( $.attr(this, 'href') ).offset().top - 330
		    }, 1400);
		})
	},
	visitaScoll: function(){
		if ($(window).outerWidth(true) > 992) {
			var controller = new smInit.Controller();
			var tl = new tmInit();
			var tween = tl.to("#visita_image", .5, {scale:1, ease: Linear.easeNone})
							.to("#visita_image", .5, {left:0, ease: Linear.easeNone}, "-=.5")
							.to("#visita_content", .5, {top: 0, opacity: 1, ease: Linear.easeNone}, "-=.4")
							.to("#visita_text", .25, {opacity: 1, top: 0, ease: Linear.easeNone}, "-=.3")
							.to("#visita_link", .25, {opacity: 1, top: 0, ease: Linear.easeNone}, "-=.25");

			var scene = new smInit.Scene({triggerElement: "#visita_trigger", duration: 400}).setTween(tween).addTo(controller);
		}
	},
	newsScroll: function(){
		var controller = new smInit.Controller();
		var tl = new tmInit();
		var tween = tl.staggerFromTo(".homePost", 1.5, {top: 240, opacity: .2}, {top: -80, opacity:1, ease: Linear.easeNone}, .8);

		// build scene
		var scene = new smInit.Scene({triggerElement: "#home-news",  duration: $(window).height()}).setTween(tween).addTo(controller);
	}
}

$(window).on('load', function(){
	page_home.load();
})
$(window).on('resize', function(){
	page_home.load();
})